<template>
  <span v-html='convertedHtml' class='markdown'></span>
</template>
<script>
import showdown from 'showdown';

showdown.extension('variableBox', () => {
  return [
    {
      type: 'lang',
      regex: /\[(\S+?)\]([^(])/g,
      replace(matched) {
        const striped = matched
          .replace('[', '')
          .replace(']', '')
          .replace('_', '&#95;')
          .trim();
        return `<span class="highlight highlight-variable">${striped}</span> `;
      },
    },
  ];
});

showdown.extension('jsonPath', () => {
  return [
    {
      type: 'lang',
      regex: /\{(\S+?)\}/g,
      replace(matched) {
        if (matched.includes(':')) {
          return matched;
        }
        const striped = matched.replace('_', '&#95;').replace('{', '').replace('}', '');
        return `<span class="highlight highlight-object">${striped}</span>`;
      },
    },
  ];
});

showdown.extension('className', () => {
  return [
    {
      type: 'lang',
      regex: /\{\[(.+?)\](.+?)\}/g,
      replace(wholeMatch, match1, match2) {
        return `<span class="${match1}">${match2}</span>`;
      },
    },
  ];
});

showdown.extension('tagRemover', () => {
  return [
    {
      type: 'lang',
      regex: /<.*?>/g,
      replace(matched) {
        const striped = matched.replace('<', '&lt;').replace('>', '&gt;');
        return striped;
      },
    },
  ];
});

export default {
  name: 'Markdown',
  props: {
    text: String,
  },
  computed: {
    convertedHtml() {
      const striped = this.text.trim();
      const converter = new showdown.Converter({
        extensions: ['tagRemover', 'className', 'jsonPath', 'variableBox'],
      });
      converter.setOption('tables', true);
      let converted = converter.makeHtml(striped);
      // Remove the enclosing p tag, it affects a lot on SSR
      // Following fix is for SSR hydration related, need to find a better way.
      converted = converted.replace(/<p>/g, '<span class="p">').replace(/<\/p>/g, '</span>');
      return converted;
    },
  },
};
</script>
