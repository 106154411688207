const isClient = process.env.RUN_ENV === 'client';

function apiBasePath() {
  let prefix = '';
  let apiPath = process.env.VUE_APP_RZ_API_PATH;
  const publicPath = process.env.VUE_APP_PUBLIC_PATH;
  if (isClient && publicPath && !(apiPath.startsWith('http'))) {
    apiPath = `${publicPath}${apiPath}`;
  }

  if (!isClient) {
    prefix = process.env.RZ_BACKEND_PATH;
  }

  return `${prefix}${apiPath}`;
}

const Config = {
  appUrlPrefix: process.env.VUE_APP_URL_PREFIX || '',
  rzLoginPath: process.env.VUE_APP_LOGIN_PATH,
  rzConsoleLoginPath: process.env.VUE_APP_CONSOLE_LOGIN_PATH || '',
  rzPublicPath: process.env.VUE_APP_PUBLIC_PATH || '',
  rzAppKey: process.env.VUE_APP_RZ_APP_KEY,
  rzApiKey: process.env.VUE_APP_RZ_API_KEY,
  rzApiPath: apiBasePath(),
  gtagID: process.env.VUE_APP_GTAG_ID || 'UA-31087175-5',
  gtmID: process.env.VUE_APP_GTM_ID || 'GTM-NPB58LT',
  optimizeID: process.env.VUE_APP_OPTIMIZE_ID || 'OPT-PWQJ6NR',
  twitterSite: process.env.VUE_APP_TWITTER_SITE || null,
  facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID || null,
  defaultMetaImage: process.env.VUE_APP_DEFAULT_META_IMAGE || null,
  isProd: process.env.NODE_ENV === 'production',
  infraEnv: process.env.VUE_APP_INFRA_ENV || 'local',
  env: process.env.NODE_ENV,
  isClientEnv: isClient,
  version: process.env.APP_VERSION || '1.0.0',

  cricketHost: process.env.VUE_APP_CRICKET_HOST || 'https://www.cricketapi.com/',
  sportsHost: process.env.VUE_APP_SPORTS_HOST || 'https://sports.roanuz.com/',
  consoleHost: process.env.VUE_APP_CONSOLE_HOST || 'https://console.sports.dev.roanuz.com/',

  stripeLocalKey: process.env.VUE_APP_RZ_STRIPE_LOCAL_KEY || 'pk_test_uYh26O8zsiVtTeIE0ASRNtRF',
  stripeKey: process.env.VUE_APP_RZ_STRIPE_KEY || 'pk_test_uYh26O8zsiVtTeIE0ASRNtRF',
  razorpayKey: process.env.VUE_APP_RZ_RAZORPAY_KEY || 'rzp_test_3lyWpw4yrA9MNP',

  antsConsole: process.env.VUE_APP_ANTS_CONSOLE_HOST
   || (process.env.VUE_APP_CONSOLE_HOST && process.env.VUE_APP_CONSOLE_HOST.replace('console', 'ants-console'))
   || 'https://ants-console.roanuz.com',
  cricketApiBackendHost: process.env.VUE_APP_CRICKETAPI_BACKEND_HOST || 'https://sports.prod.roanuz.com',
};

export default Config;
