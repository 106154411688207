/* eslint-disable no-underscore-dangle */
import { BaseType } from '@/store/modules/base';

export class EnumValue extends BaseType {
  constructor(key, value, kind) {
    super();
    this.key = key;
    this.value = value;
    this.kind = kind;
    this[`is${key}`] = true;
  }

  static typeName() {
    return 'EnumValue';
  }

  get friendlyName() {
    let name = this.value.replace(/_/, ' ');
    name = name.replace(/:/, ' ');
    return name;
  }

  // toJSON() {
  //   const { key, value } = this;
  //   return { key, value };
  // }

  is(anotherEnum) {
    return this.key === anotherEnum.key;
  }
}

// eslint-disable-next-line import/prefer-default-export
export class Enum extends BaseType {
  constructor(values) {
    super();

    Object.keys(values).forEach((key) => {
      this[key] = new EnumValue(key, values[key], this);
    });
    this._values = values;
  }

  static typeName() {
    return 'Enum';
  }

  static createFromJson(raw) {
    const data = new this(raw._values);
    return data;
  }

  toJSON() {
    const { _values, rzTypeName } = this;
    return { _values, rzTypeName };
  }

  parse(raw) {
    let rawValue = raw;
    if (raw.value) {
      // Trying to parse serialized enum object
      rawValue = raw.value;
    }
    let found = null;
    Object.keys(this._values).forEach((key) => {
      if (this[key].value === rawValue) {
        found = this[key];
      }
    });
    if (found === null) {
      throw Error(`Value ${rawValue} not found in ${Object.values(this._values)}`);
    }

    return found;
  }

  praseSafe(raw, defaultValue) {
    try {
      return this.parse(raw);
    } catch (error) {
      if (!defaultValue) {
        return null;
      }
      return defaultValue;
    }
  }
}
