<template>
  <div
   class="image-item"
   v-if="image.url"
   :class="[image.element_class]">
      <div
        class="loader animate-container"
        :class="[imageLoaded? 'hide-container': 'show-container']"
        :style="{backgroundx: image.placeholder_color}">
        <div class="placeholder-data">
          <img
            class="placeholder-data-img"
            v-if="image.data_uri"
          :src="image.data_uri"
          :alt="image.alt">
        </div>
      </div>
      <div
        class="image-container animate-container"
        style="imageBackgroundStyle"
        :class="[(!imageLoaded)? 'hide-container': 'show-container']">
        <picture>
          <source
            v-for="(variation, i) in image.variations"
            :key="i"
            :srcset="variation.srcsetString"
            :media="variation.for_media"
            :type="variation.for_type"
            :sizes="variation.for_sizes"
          />
          <img
            v-if="readyToRender"
            :class="containerWidth"
            :src="image.url"
            :alt="image.alt"
            :srcset="image.srcsetString"
            @load="onImageLoad">
        </picture>
      </div>
  </div>
</template>
<style lang="scss">
  .image-item{
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
    z-index: 1;
    &.align-left {
      text-align: left;
    }

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
    }

    .loader, .placeholder-data, .placeholder-data-img {
      height: 100%;
    }

    picture, img {
      text-align: center;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
      &.max-width-90 {
        width: size(90);
      }
    }

    .image-container {
      height: 100%;

      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

    }

    .animate-container {
      transition: opacity 450ms ease-out;
    }

    .show-container {
      opacity: 1;
    }

    .hide-container {
      opacity: 0;
    }
  }
</style>
<script>
export default {
  name: 'ImageSet',
  props: {
    image: {
      type: Object,
    },
    containerWidth: {
      type: String,
      default: '',
    },
  },

  computed: {
    imageBackgroundStyle() {
      if (this.image.data_uri) {
        return { 'background-image': `url(${this.image.data_uri})` };
      }
      return { background: this.placeholder_color };
    },
  },

  data() {
    return {
      observer: null,
      intersected: false,
      imageLoaded: false,
      readyToRender: false,
    };
  },

  mounted() {
    this.observeVisiblity();
  },

  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  methods: {
    onImageLoad() {
      this.imageLoaded = true;
    },

    onElementVisible() {
      this.readyToRender = true;
    },

    observeVisiblity() {
      if (!IntersectionObserver) {
        // Browser not supported.
        console.log('Browser not supported for IntersectionObserver');
        this.onElementVisible();
        return;
      }

      this.observer = new IntersectionObserver((entries) => {
        if (this.intersected) { return; }
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          this.intersected = true;
          this.observer.disconnect();
          this.onElementVisible();
        }
      });

      this.$nextTick(() => {
        if (this.observer) {
          this.observer.observe(this.$el);
        }
      });
    },
  },
};
</script>
