// runs in browser only

import { HydrationHelper } from '@/store/plugins/hydration';
import { createApp } from './main';

let newState = null;

// eslint-disable-next-line no-underscore-dangle
if (window.__INITIAL_STATE__) {
  // We initialize the store state with the data injected from the server
  // eslint-disable-next-line no-underscore-dangle
  // store.replaceState(window.__INITIAL_STATE__);

  // eslint-disable-next-line no-underscore-dangle
  // console.log(JSON.stringify(window.__INITIAL_STATE__));

  // eslint-disable-next-line no-underscore-dangle
  newState = HydrationHelper.parseRawState(window.__INITIAL_STATE__);
  // console.log(JSON.parse(JSON.stringify(newState)));
  // store.replaceState(newState);
}

const { app, router } = createApp(newState);

router.onReady(() => {
  console.log('Ready to load');
  app.$mount('#app', true);
});

router.onError((err) => {
  console.log('Error', err);
});
