import Vue from 'vue';
import { HydrationHelper } from '@/store/plugins/hydration';
import { RequestState, RequestStateStatus } from '@/api/core';
import { Enum } from '@/core/utils/enum';
import { WebPage } from './page';

export const Authors = new Enum({
  Ajai_Kannan: 'ajai_kannan',
  Divya_Gurunath: 'divya_gurunath',
  Goutham: 'goutham',
  Nooreash: 'nooreash',
  Anto: 'anto',
  Arun: 'arun',
  Abhinaya: 'Abhinaya',
});

export class ArticleList extends WebPage {
  constructor() {
    super();
    this.loaded = false;
  }

  static createFromJson(raw) {
    let data = new ArticleList();
    data = Object.assign(data, raw);
    return data;
  }

  static fetchArticleList(apiInstance, inMemoryCache, params, routeName, projectKey) {
    let cacheKey = 'articlelist';
    if (params.limit) {
      cacheKey += `_${params.limit}`;
    }
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }
    // eslint-disable-next-line no-underscore-dangle
    params._usefresh = 'random-123';
    params.proj_key = projectKey;
    return apiInstance
      .get('/endpoint/article/list/', {
        params,
      })
      .then((resp) => {
        const articleList = ArticleList.createFromJson(resp.data.data);
        articleList.article.forEach((article) => {
          article.author_keys.forEach((author, index) => {
            article.author_keys[index] = Authors.parse(author);
          });
        });
        articleList.routeValue = routeName;
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = articleList;
        }
        return articleList;
      });
  }
}

HydrationHelper.registerType(ArticleList);

const initState = () => ({
  activeArticleList: new WebPage(),
  activeArticleState: new RequestState(),
  activeArticleListState: new RequestState(),
});

const getters = {};

const actions = {
  fetchArticleList({ commit, rootState }, { params, routeName, projectKey }) {
    commit('updateActiveArticleListStatus', { status: RequestStateStatus.Loading });
    return new Promise((resolve) => {
      ArticleList.fetchArticleList(rootState.apiInstance, rootState.inMemoryCache,
        params, routeName, projectKey).then((data) => {
        commit('updateActiveArticleList', data);
        commit('updateActiveArticleListStatus', { status: RequestStateStatus.Loaded });
        resolve(data);
      }).catch((err) => {
        console.log('Error while fetching article page list ');
        commit('updateActiveArticleListStatus', { status: RequestStateStatus.Error, msg: err });
      });
    });
  },
};

const mutations = {
  updateActiveArticleList(state, data) {
    Vue.set(state, 'activeArticleList', data);
  },
  updateActiveArticleListStatus(state, { status, msg }) {
    state.activeArticleListState.status = status;
    state.activeArticleListState.ref = msg;
  },
  resetActivePage(state) {
    Vue.set(state, 'activeArticleList', new WebPage());
    Vue.set(state, 'activeArticleListState', new RequestState());
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
