import Config from '@/config';

export class APISampleResponse {
  static createFromJson(raw) {
    let data = new APISampleResponse();
    data = Object.assign(data, raw);
    return data;
  }

  downloadUrl() {
    const url = '/endpoint/apidocs/api-example/';
    const basePath = Config.rzApiPath;
    const key = encodeURIComponent(this.response_key);
    return `${basePath}${url}?key=${key}&download=1&rz-api-key=${Config.rzApiKey}`;
  }

  fetchFile(apiInstance) {
    const params = { key: this.response_key };
    return apiInstance
      .get('/endpoint/apidocs/api-example/', { params })
      .then((resp) => resp.data.data.example);
  }

  static fetchResponses(apiInstance, params) {
    return apiInstance
      .get('/endpoint/apidocs/api-example/', { params })
      .then((resp) => resp.data.data.example);
  }
}
