<template>
  <span v-html="convertedHtml" class="json-path"></span>
</template>
<script>
export default {
  name: 'JSONPath',
  props: {
    text: String,
  },
  computed: {
    convertedHtml() {
      return this.convertToHtml(this.text);
    },
  },
  methods: {
    tokenHtml(text, tokens, cls) {
      return text.replace(
        tokens[0],
        `<span class="variable-box ${cls}">${tokens[1]}</span>`,
      );
    },

    convertToHtml(text) {
      if (!text) {
        return '';
      }

      const regexList = [
        [/{(.*?)}/g, 'dict'],
        [/\[(.*?)\]/g, 'list'],
      ];

      let converted = text;

      regexList.forEach(([rx, cls]) => {
        let tokens = rx.exec(converted);
        while (tokens) {
          converted = this.tokenHtml(converted, tokens, cls);
          tokens = rx.exec(converted);
        }
      });

      return converted;
    },
  },
};
</script>
