import Vue from 'vue';

import { Enum } from '@/core/utils/enum';

import { BillingProfile, Currencies } from '@/store/modules/paygo';
import { HydrationHelper } from '@/store/plugins/hydration';
import { BaseType } from './base';

export const ProjectCreationState = new Enum({
  NotInit: 'Not Initiated',
  Loading: 'Loading',
  Initiated: 'initiated',
  LicenseInitiated: 'select_license',
  LicenseSelected: 'License Selected',
  UpfrontInitiated: 'select_upfront',
  UpfrontSelected: 'Upfront Selected',
  BehaviourInitiated: 'select_behaviour',
  BehaviourSelected: 'Behaviour Selected',
  PrepayInitiated: 'prepay',
  PrepayFilled: 'Prepay Filled',
  PaymentInitiated: 'do_payment',
  PaymentCompleted: 'Payment Completed',
  ReadyToGo: 'Ready To Go',
  Error: 'Error',
});

export const ProjectBehaviour = new Enum({
  SetBilling: 'set_profile',
  Prepayment: 'prepayment',
  FreeVirtualSports: 'free_sports',
});

export class ProjectController extends BaseType {
  constructor(status) {
    super();
    this.status = status || ProjectCreationState.Initiated;
    this.behaviour = ProjectBehaviour.SetBilling;
    this.prePayAmount = 100;
    this.upFrontProduct = null;
    this.prePaidProduct = null;
    this.currency = Currencies.USD.value.value;
    this.upFrontAmount = null;
    this.offerAmount = 0;
    this.subTotalPay = 0;
    this.ref = null;
  }

  static typeName() {
    return 'ProjectController';
  }
}

export class Project extends BaseType {
  constructor() {
    super();
    this.key = null;
    this.name = null;
    this.license_key = null;
    this.company_name = null;
    this.licenseInfo = null;
    this.billing = new BillingProfile();
  }

  static createFromJson(raw) {
    let data = new Project();
    data = Object.assign(data, raw);

    if (raw) {
      if (raw.project_license && raw.project_license.length) {
        const [license] = raw.project_license;
        data.licenseInfo = license;
        data.license_key = license.product;
      } else {
        const license = {
          active_from: 1612884817,
          active_until: null,
          committed_price: null,
          common_name: 'Enterprise',
          created: 1612884817,
          is_active: true,
          metadata: null,
          name: 'Enterprise License (C1X)',
          product: 'rs_paygo_enterprise_c1x',
          project_product: '1359163553944309845',
        };
        data.licenseInfo = license;
        data.license_key = license.product;
      }
      if (raw?.billing) {
        data.billing = BillingProfile.createFromJson(raw.billing);
      }
    }
    return data;
  }

  static createProject(apiInstance, data) {
    return apiInstance
      .post('/business/user/signup/create_project/', data)
      .then((resp) => resp.data.data);
  }

  static fetch(apiInstance, projectKey) {
    return apiInstance
      .get(`/business/project/${projectKey}/`)
      .then((resp) => Project.createFromJson(resp.data.data));
  }

  updateBillingProfile(apiInstance, store, data) {
    return apiInstance
      .post('/business/user/billing/actions/', data)
      .then((resp) => {
        const billInfo = BillingProfile.createFromJson(resp.data.data);
        store.commit('project/updateBillingProfile', billInfo);
        return billInfo;
      });
  }

  static updateFreeTrailDetails(apiInstance, projectKey, data) {
    return apiInstance
      .post(`business/project/${projectKey}/actions/`, data)
      .then((resp) => resp.data);
  }

  static typeName() {
    return 'Project';
  }
}

HydrationHelper.registerType(Project);
HydrationHelper.registerType(ProjectController);

const initState = () => ({
  activeProject: new Project(),
  activeProjectCtrl: new ProjectController(),
});

const getters = {};

const actions = {
  fetchProject({ commit, rootState }, projectKey) {
    return new Promise((resolve, reject) => {
      commit('updateActiveProjectStatus', { status: ProjectCreationState.Loading });
      Project.fetch(rootState.apiInstance, projectKey).then((project) => {
        commit('updateActiveProject', project);
        if (project.billing) {
          commit('updateCurrency', project.billing.billing_currency);
        }
        commit('updateActiveProjectStatus', { status: ProjectCreationState.Initiated });
        resolve(project);
      }).catch((err) => {
        commit('updateActiveProjectStatus', { status: ProjectCreationState.Error, msg: err });
        reject(err);
      });
    });
  },
};

const mutations = {
  updateActiveProject(state, project) {
    state.activeProject = project;
  },

  updateActiveProjectStatus(state, { status, msg }) {
    state.activeProjectCtrl.status = status;
    state.activeProjectCtrl.ref = msg;
  },

  updateProjectBehaviour(state, { behaviour, payGoResources }) {
    if (behaviour.isSetBilling) {
      state.activeProjectCtrl.status = ProjectCreationState.PaymentInitiated;
    } else if (behaviour.isPrepayment) {
      if (payGoResources && payGoResources.prePaidAmounts) {
        const [prePaidProduct] = payGoResources.prePaidAmounts;
        state.activeProjectCtrl.prePaidProduct = prePaidProduct;
        state.activeProjectCtrl.status = ProjectCreationState.PrepayInitiated;
      }
    }
  },

  updateBillingProfile(state, billInfo) {
    state.activeProject.billing = billInfo;
  },

  updateProjectCtrl(state, { param, value }) {
    Vue.set(state.activeProjectCtrl, param, value);
  },

  updateCurrency(state, currency) {
    Vue.set(state.activeProjectCtrl, 'currency', currency);
    if (state.activeProjectCtrl.upFrontProduct) {
      state.activeProjectCtrl.upFrontAmount = state.activeProjectCtrl.upFrontProduct
        .price.unit_amount[state.activeProjectCtrl.currency];
      // state.activeProjectCtrl.status = ProjectCreationState.PaymentInitiated;
      const offerAmount = state.activeProjectCtrl.upFrontProduct
        .price.extra_credits_amount[state.activeProjectCtrl.currency];
      if (offerAmount) {
        state.activeProjectCtrl.offerAmount = offerAmount;
      }
      state.activeProjectCtrl.subTotalPay = state.activeProjectCtrl.upFrontAmount;
    }
  },

  updatePrePayAmount(state, prePayAmount) {
    state.activeProjectCtrl.prePayAmount = prePayAmount;
    state.activeProjectCtrl.offerAmount = 0;
    state.activeProjectCtrl.subTotalPay = prePayAmount;
  },

  updateUpfrontProduct(state, product) {
    if (product) {
      state.activeProjectCtrl.upFrontProduct = product;
      state.activeProjectCtrl.upFrontAmount = product.price.unit_amount[state.activeProjectCtrl.currency];
      const offerAmount = product.price.extra_credits_amount[state.activeProjectCtrl.currency];
      if (offerAmount) {
        state.activeProjectCtrl.offerAmount = offerAmount;
      }
      state.activeProjectCtrl.subTotalPay = state.activeProjectCtrl.upFrontAmount;
    } else {
      state.activeProjectCtrl.upFrontProduct = null;
      state.activeProjectCtrl.upFrontAmount = 0;
      state.activeProjectCtrl.offerAmount = 0;
      state.activeProjectCtrl.subTotalPay = 0;
      state.activeProjectCtrl.status = ProjectCreationState.BehaviourInitiated;
    }
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
