import Config from '@/config';

export function pageHeadDefaults() {
  return {
    title: 'Roanuz Sports ...',
    gtagID: Config.gtagID,
    gtmID: Config.gtmID,
    optimizeID: Config.optimizeID,
  };
}

export class PageMetaItem {
  constructor(content, isProperty = true) {
    this.content = content;
    this.isProperty = isProperty;
  }
}

export function pageHeadMetaDefaults(vm) {
  let url = vm.$route.path;
  url = `${Config.appUrlPrefix}${url}`;

  return {
    url,
  };
}

export class PageMetaData {
  constructor(title, description, {
    image = null, twitterCard = 'summary', url = null, otherMetaTags = null,
  } = {}) {
    this.title = title;
    this.description = description;
    this.image = image || Config.defaultMetaImage;
    this.url = url;
    this.twitterCard = twitterCard;
    this.otherMetaTags = otherMetaTags;
    this.twitterSite = Config.twitterSite;
    this.facebookAppId = Config.facebookAppId;
  }

  updateDefaults(defaults) {
    Object.keys(defaults).forEach((key) => {
      if (!this[key]) {
        this[key] = defaults[key];
      }
    });
  }

  build() {
    const meta = {
      description: new PageMetaItem(this.description, false),
      'og:title': new PageMetaItem(this.title),
      'og:description': new PageMetaItem(this.description),
      'og:url': new PageMetaItem(this.url),
      'twitter:card': new PageMetaItem(this.twitterCard, false),
    };

    if (this.image) {
      meta['og:image'] = new PageMetaItem(this.image);
    }

    if (this.twitterSite) {
      meta['twitter:site'] = new PageMetaItem(this.twitterSite, false);
    }

    if (this.facebookAppId) {
      meta['fb:app_id'] = new PageMetaItem(this.facebookAppId);
    }

    if (this.otherMetaTags) {
      Object.assign(meta, this.otherMetaTags);
    }

    return meta;
  }

  injectTitle(vm, title) {
    if (Config.isClientEnv) {
      document.title = title;
    } else {
      vm.$ssrContext.title = title;
    }
  }

  inject(vm) {
    this.injectTitle(vm, this.title);

    if (Config.isClientEnv) {
      return;
    }

    const metaDataItems = this.build();
    const pageDefaults = pageHeadDefaults();

    const metaRaw = [];
    Object.keys(metaDataItems).forEach((name) => {
      const item = metaDataItems[name];
      const { content } = item;

      if (content) {
        if (item.isProperty) {
          metaRaw.push(`<meta property="${name}" content="${content}" />`);
        } else {
          metaRaw.push(`<meta name="${name}" content="${content}" />`);
        }
      }
    });

    vm.$ssrContext.meta = metaRaw.join('\n');
    vm.$ssrContext.gtagID = pageDefaults.gtagID;
    vm.$ssrContext.gtmID = pageDefaults.gtmID;
    vm.$ssrContext.optimizeID = pageDefaults.optimizeID;
  }

  static getVMData(prop, vm) {
    const data = vm.$options[prop];
    if (data) {
      return typeof data === 'function'
        ? data.call(vm)
        : data;
    }

    return undefined;
  }

  static newFromVM(vm) {
    const vmData = PageMetaData.getVMData('pageMetaData', vm);
    const metaData = vmData || new PageMetaData();

    const title = PageMetaData.getVMData('title', vm);
    if (title) {
      metaData.title = title;
    }

    const metaDefaults = pageHeadMetaDefaults(vm);
    metaData.updateDefaults(metaDefaults);

    return metaData;
  }
}
