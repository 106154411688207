export class AssetSourceSet {
  get srcsetString() {
    if (this.descriptor) {
      return `${this.url} ${this.descriptor}`;
    }

    return this.url;
  }

  static createFromJson(raw) {
    let data = new AssetSourceSet();
    data = Object.assign(data, raw);
    return data;
  }
}

export class AssetSource {
  get hasQueryVariations() {
    return this.for_media || this.for_type || this.for_sizes;
  }

  get srcsetString() {
    return this.src_set.map((x) => x.srcsetString).join(', ');
  }

  static createFromJson(raw) {
    let data = new AssetSource();
    data = Object.assign(data, raw);
    data.src_set = raw.src_set.map((x) => AssetSourceSet.createFromJson(x));
    return data;
  }
}

// Refer
// - https://alligator.io/html/picture-element/
// - https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture
export class ImageData {
  get srcsetString() {
    if (this.selfVariation) {
      return this.selfVariation.srcsetString;
    }

    return '';
  }

  static createFromJson(raw) {
    let data = new ImageData();
    data = Object.assign(data, raw);

    if (raw.variations) {
      data.variations = raw.variations.map((x) => AssetSource.createFromJson(x));
    } else {
      data.variations = [];
    }

    data.selfVariation = null;
    // If no query based src, just use srcset attribute instead of source tag
    if (data.variations.length > 0 && (!data.variations[0].hasQueryVariations)) {
      data.selfVariation = data.variations.shift();
    }

    return data;
  }
}
