import Vue from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import { HydrationHelper } from '@/store/plugins/hydration';
import { Enum } from '@/core/utils/enum';
import { BaseType } from './base';


export const WizardStep = new Enum({
  SelectPlan: 'select_plan',
  SelectProject: 'select_project',
  ReviewPlan: 'review_plan',
  ProjectName: 'project_name',
  ChooseLicense: 'choose_license',
  CommittedUse: 'commited_use',
  Prepay: 'prepay',
  Billing: 'billing',
  Payment: 'payment',
  BillingMethod: 'billing_method',
  Completed: 'completed',
});

export const FirstStep = WizardStep.CommittedUse;

export const BillingMethods = [
  {
    key: 'billing',
    title: 'Set up Billing profile',
    desc: 'Pay for usage at the end of each month.'
    + ' You can either Pay by Invoice or set up automated payments using your Credit or Debit Card',
  },
  {
    key: 'prepay',
    title: 'Prepay and start accessing all live data',
    desc: 'Add money to your Roanuz Project now and access data.',
  },
  {
    key: 'free',
    title: 'Try for free',
    desc: 'Explore our API endpoints with event-driven data powered by Virtual Sports.'
    + ' Come back and set up a billing profile later.',
  },
];


const PMFutureInvoiceMeta = {
  name: 'Pay by invoice',
  title: ' Your Billing profile will be verified by our team.'
  + ' Invoices are generated on the first of every month. Due in 7 days.',
  desc: '',
  icon: 'invoice-icon',
};
const PMInvoiceMeta = {
  name: 'Pay by invoice',
  title: 'Account details along with payment instructions will be sent to you through Email.',
  desc: 'We will email you bank details with the invoice to make wired transfer.',
  icon: 'invoice-icon',
};

export const PaymentMethodMeta = {
  'invoice|razorpay': PMInvoiceMeta,
  'invoice|international': PMInvoiceMeta,
  'invoice|paypal': {
    name: 'Paypal',
    title: 'Pay with your Paypal account.',
    desc: 'We will email you Paypal account details with the invoice to make payment.',
    icon: 'paypal-icon',
  },
  'gateway|paypal': {
    name: 'Paypal',
    title: 'Pay with your Paypal account.',
    desc: '',
    icon: 'paypal-icon',
  },
  'gateway|razorpay': {
    name: 'Razorpay',
    title: 'Indian Payment gateway',
    desc: '',
    icon: 'razorpay_logo',
  },
  'card|stripe': {
    name: 'Credit or Debit Card',
    title: 'International Credit and Debit cards are processed through the Stripe payment gateway.',
    desc: '',
    icon: '',
  },
};
export const PaymentMethodFutureMeta = {
  'invoice|razorpay': PMFutureInvoiceMeta,
  'invoice|international': PMFutureInvoiceMeta,
  'invoice|paypal': {
    name: 'Paypal',
    title: 'Pay with your Paypal account.',
    desc: 'We will email you Paypal account details with the invoice to make payment.',
    icon: 'paypal-icon',
  },
  'gateway|paypal': {
    name: 'Paypal',
    title: 'Pay with your Paypal account.',
    desc: '',
    icon: 'paypal-icon',
  },
  'gateway|razorpay': {
    name: 'Razorpay',
    title: 'Indian Payment gateway',
    desc: '',
    icon: 'razorpay_logo',
  },
  'card|stripe': {
    name: 'Pay by Card',
    title: 'We will now verify your card by debiting {currency1} from your account based on your payment currency.'
    + ' On successful verification, the deducted amount will be discounted from your next bill.',
    desc: '',
    icon: '',
  },
};

export const DefaultBillingMethod = BillingMethods[0].key;

export class PaymentMethod {
  get key() {
    return `${this.payment_medium}|${this.payment_gateway}`;
  }

  get name() {
    return this.getMeta('name');
  }

  get title() {
    return this.getMeta('title');
  }

  get desc() {
    return this.getMeta('desc');
  }

  get icon() {
    return this.getMeta('icon');
  }

  getMeta(prop) {
    if (this.is_future_payment) {
      if (PaymentMethodFutureMeta[this.key] && PaymentMethodFutureMeta[this.key][prop]) {
        return PaymentMethodFutureMeta[this.key][prop];
      }
    }
    if (PaymentMethodMeta[this.key] && PaymentMethodMeta[this.key][prop]) {
      return PaymentMethodMeta[this.key][prop];
    }
    return '';
  }

  static createFromJson(raw) {
    let data = new PaymentMethod();
    data = Object.assign(data, raw);
    return data;
  }
}

export class BillingAddress extends BaseType {
  constructor() {
    super();
    this.company_name = null;
    this.city = null;
    this.contact_email = null;
    this.contact_name = null;
    this.state = null;
    this.zip_code = null;
    this.address = null;
    this.billing_currency = null;
    this.country_code = null;
    this.phone_number = null;
    this.gst_number = null;
    this.key = null;
    this.is_draft = true;

    // Sample data
    // this.company_name = null;
    // this.gst_number = null;
    // this.city = 'Chennai';
    // this.contact_email = 'anto@roanuz.com';
    // this.contact_name = 'Anto';
    // this.state = 'TN';
    // this.city = 'Chennai';
    // this.zip_code = '600090';
    // this.address = 'Besant Nagar';
  }

  get isForBusiness() {
    return this.company_name || this.gst_number;
  }

  preparePayload() {
    return this;
  }

  static createFromJson(raw) {
    let data = new BillingAddress();
    data = Object.assign(data, raw);
    if (!data.state && data.state_name) {
      data.state = data.state_name;
    }
    if (data.state_name) {
      delete data.state_name;
    }
    return data;
  }

  static typeName() {
    return 'BillingAddress';
  }
}

export class CartPreviewResponse extends BaseType {
  get isPaymentRequired() {
    if (this.grand_total >= 0) {
      return true;
    }
    return false;
  }

  static createFromJson(raw) {
    let data = new CartPreviewResponse();
    data = Object.assign(data, raw);

    if (raw?.billing) {
      data.billing = BillingAddress.createFromJson(raw.billing);
    }

    if (raw && raw.valid_payment_methods) {
      data.valid_payment_methods = raw.valid_payment_methods.map((x) => PaymentMethod.createFromJson(x));
    }

    return data;
  }

  static forProjectSetup() {
    const project = {
      name: '',
      project_license: [],
    };
    return this.createFromJson({ project });
  }

  static typeName() {
    return 'CartPreviewResponse';
  }
}

export const CheckoutResultKind = new Enum({
  Nothing: 'nothing',
  GetInTouch: 'get_in_touch',
  AskedForFree: 'asked_for_free',
  CardVerified: 'card_verified',
  VerificationRequired: 'verification_required',
  InvoiceCreated: 'invoice_created',
  PaymentReceived: 'payment_received',
  PaymentError: 'payment_error',
});

export class CheckoutResult extends BaseType {
  constructor() {
    super();
    this.kind = CheckoutResultKind.Nothing;
    this.invoiceKey = null;
    this.transactionKey = null;
  }

  static createFromJson(raw) {
    let data = new CheckoutResult();
    data = Object.assign(data, raw);
    return data;
  }

  static typeName() {
    return 'CheckoutResult';
  }
}

export class PaymentWizard extends BaseType {
  constructor() {
    super();
    this.step = FirstStep;
    this.firstStep = FirstStep;
    this.requestNextStep = null;
    this.committedUseItems = null;
    this.billingMethods = BillingMethods;
    this.selectedBillingMethod = DefaultBillingMethod;
    this.selectedPaymentMethod = null;
    this.result = new CheckoutResult();
    this.licenses = [];
    this.paymentForwardParams = {};
    this.givenProjectName = '';
    this.selectedLicense = '';
  }

  forward(cartInput) {
    if (this.step.isCommittedUse) {
      if (cartInput.committedUseProduct) {
        this.requestNextStep = WizardStep.Payment;
      } else {
        this.requestNextStep = WizardStep.BillingMethod;
      }
    } else if (this.step.isBillingMethod) {
      if (this.selectedBillingMethod === 'billing') {
        this.requestNextStep = WizardStep.Billing;
      } else if (this.selectedBillingMethod === 'prepay') {
        this.requestNextStep = WizardStep.Prepay;
      } else {
        this.result.kind = CheckoutResultKind.AskedForFree;
        this.requestNextStep = WizardStep.Completed;
      }
    } else if (this.step.isPrepay) {
      this.requestNextStep = WizardStep.Payment;
    }

    console.log(`Wizard moving to ${this.requestNextStep.key}`);
  }

  markDone() {
    this.requestNextStep = WizardStep.Completed;
    console.log(`Wizard moving to ${this.requestNextStep.key}`);
  }

  updateStep(step) {
    this.step = step;
  }

  fetchCommittedUseItems(apiInstance, inMemoryCache, currency) {
    const cacheKey = `cricket_upfronts_raw_${currency}`;
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }

    return apiInstance
      .get(`/business/upfronts/?sort_currency=${currency}&sport=cricket`)
      .then((resp) => {
        const data = resp.data.data.upfronts;
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }

  projectsList(apiInstance) {
    return apiInstance
      .get('business/user/projects/list/');
  }

  fetchLicenses(apiInstance) {
    return apiInstance
      .get('business/licenses/?for_checkout_flow=1&version=v2');
  }

  createProject(apiInstance) {
    const payload = {
      name: this.givenProjectName,
      license: this.selectedLicense,
    };

    return apiInstance
      .post('/business/user/signup/create_project/', payload);
  }

  setupFuturePayments(apiInstance, cartInput) {
    const payload = {
      project_key: cartInput.projectKey,
      billing_key: cartInput.billing.key,
      payment_medium: this.selectedPaymentMethod.payment_medium,
      payment_gateway: this.selectedPaymentMethod.payment_gateway,
    };

    return apiInstance
      .post(`business/payments-handle/${cartInput.projectKey}/future-payment/create/`, payload);
  }

  confirmFuturePayments(apiInstance, cartInput, payload) {
    const updatedPayload = {
      project_key: cartInput.projectKey,
      billing_key: cartInput.billing.key,
      payment_medium: this.selectedPaymentMethod.payment_medium,
      payment_gateway: this.selectedPaymentMethod.payment_gateway,
      payload,
    };

    return apiInstance
      .post(`business/payments-handle/${cartInput.projectKey}/future-payment/confirm/`, updatedPayload);
  }

  invoiceDetails(apiInstance, cartInput, edit) {
    const cartPayload = cartInput.preparePayload();
    const updatedPayload = {
      project_key: cartInput.projectKey,
      billing_key: cartInput.billing.key,
      coupon_code: cartInput.couponCode ?? null,
      payment_medium: this.selectedPaymentMethod.payment_medium,
      payment_gateway: this.selectedPaymentMethod.payment_gateway,
      wait_for_confirm: this.selectedPaymentMethod.payment_medium !== 'invoice',
      invoice_type: 'purchase',
    };
    if (edit) {
      updatedPayload.key = cartInput.invoiceKey;
    } else {
      updatedPayload.items = cartPayload.items;
    }
    return updatedPayload;
  }

  createInvoice(apiInstance, cartInput) {
    const payload = this.invoiceDetails(apiInstance, cartInput);
    return apiInstance
      .post(`business/payments-handle/${cartInput.projectKey}/invoice/create/`, payload);
  }

  editInvoice(apiInstance, cartInput) {
    const edit = true;
    const payload = this.invoiceDetails(apiInstance, cartInput, edit);
    return apiInstance
      .post(`business/payments-handle/${cartInput.projectKey}/invoice/edit/`, payload);
  }

  confirmInvoice(apiInstance, cartInput) {
    const updatedPayload = {
      key: cartInput.invoiceKey,
    };
    return apiInstance
      .post(`business/payments-handle/${cartInput.projectKey}/invoice/confirm/`, updatedPayload);
  }

  createTransaction(apiInstance, cartInput, prevTransactionKey, cardStatus) {
    const updatedPayload = {
      invoice_keys: [cartInput.invoiceKey],
      payment_medium: this.selectedPaymentMethod.payment_medium,
      payment_gateway: this.selectedPaymentMethod.payment_gateway,
      cancel_prev_transaction_key: prevTransactionKey,
      skip_notification: true,
      save_card: cardStatus,
    };

    return apiInstance
      .post(`business/payments-handle/${cartInput.projectKey}/transaction/create/`, updatedPayload);
  }

  markPaymentReceived(apiInstance, cartInput, payload) {
    const updatedPayload = {
      ...payload,
      payment_medium: this.selectedPaymentMethod.payment_medium,
      payment_gateway: this.selectedPaymentMethod.payment_gateway,
    };

    return apiInstance
      .post(`business/payments-handle/${cartInput.projectKey}/payment/received/`, updatedPayload);
  }

  billingProfilePayload(apiInstance, cartInput, cartPreview) {
    const payload = cartInput.billing.preparePayload();
    const total = cartPreview.grand_total;
    if (!cartInput.billing.is_draft && total >= 0) {
      const updatedPayload = {
        ...payload,
        project_key: cartInput.projectKey,
      };
      return updatedPayload;
    }
    if (this.selectedPaymentMethod) {
      const updatedPayload = {
        ...payload,
        project_key: cartInput.projectKey,
        preferred_payment_medium: this.selectedPaymentMethod.payment_medium,
        preferred_payment_gateway: this.selectedPaymentMethod.payment_gateway,
        is_future_payment_preferred: this.selectedPaymentMethod.is_future_payment,
      };
      return updatedPayload;
    }
    const updatedPayload = {
      ...payload,
      project_key: cartInput.projectKey,
      preferred_payment_medium: null,
      preferred_payment_gateway: null,
      is_future_payment_preferred: null,
    };
    return updatedPayload;
  }

  createBillingProfile(apiInstance, cartInput, cartPreview) {
    const payload = this.billingProfilePayload(apiInstance, cartInput, cartPreview);
    return apiInstance
      .post(`business/payments-handle/${cartInput.projectKey}/billing-profile/create/`, payload);
  }

  updateBillingProfile(apiInstance, cartInput, cartPreview) {
    const payload = this.billingProfilePayload(apiInstance, cartInput, cartPreview);
    return apiInstance
      .post(`business/payments-handle/${cartInput.projectKey}/billing-profile/update/`, payload);
  }

  static createFromJson(raw) {
    let data = new PaymentWizard();
    data = Object.assign(data, raw);
    data.step = WizardStep.parse(raw.step);
    return data;
  }

  static typeName() {
    return 'PaymentWizard';
  }
}

export class CartPreviewInputItem {
  constructor(key) {
    this.key = key || null;
    this.quantity = 1;
    this.customAmount = 0;
  }

  preparePayload() {
    return {
      key: this.key,
      quantity: this.quantity,
      custom_amount: this.customAmount,
      ...(this.project_product_key && {
        project_product_key: this.project_product_key,
      }),
    };
  }
}

export class CartPreviewInput extends BaseType {
  constructor() {
    super();
    this.projectKey = null;
    this.billingKey = null;
    this.billingRegion = null;
    this.state = null;
    this.billingCurrency = null;
    this.invoiceType = null;
    this.state = null;
    this.invoiceKey = null;
    this.committedUseProduct = null;
    this.prepayAmount = null;
    this.prepayAmountKey = null;
    this.selectedLicenseKey = null;
    this.quantity = null;
    this.couponCode = null;
    this.billing = new BillingAddress();
    this.project_product_key = null;
  }

  static typeName() {
    return 'CartPreviewInput';
  }

  get canChangeRegion() {
    return (!this.billing.key) || (this.billing.key && this.billing.is_draft);
  }

  updateCurrency(currency) {
    this.billingCurrency = currency;

    // TODO: @arun keep all currency and regions in one place
    if (currency === 'inr') {
      this.billingRegion = 'India';
    } else if (currency === 'usd') {
      this.billingRegion = 'International';
    } else {
      this.billingRegion = 'Europe';
    }
  }

  preparePayload() {
    const items = [];
    if (this.committedUseProduct) {
      items.push({
        key: this.committedUseProduct,
        quantity: this.quantity || 1,
        custom_amount: 0,
        ...(this.project_product_key && {
          project_product_key: this.project_product_key,
        }),
      });
    }

    if (this.prepayAmount && this.prepayAmountKey) {
      items.push({
        key: this.prepayAmountKey,
        quantity: this.quantity || 1,
        custom_amount: this.prepayAmount,
        ...(this.project_product_key && {
          project_product_key: this.project_product_key,
        }),
      });
    }

    return {
      project_key: this.projectKey,
      billing_key: this.billingKey,
      billing_region: this.billingRegion,
      state: this.state,
      // billing_currency: this.billingCurrency,
      invoice_type: (this.invoiceType) ? this.invoiceType.value : null,
      invoice_key: this.invoiceKey,
      selected_license_key: this.selectedLicenseKey,
      coupon_code: this.couponCode,
      items,
    };
  }

  fetchResponse(apiInstance) {
    const payload = this.preparePayload();
    if (this.projectKey) {
      return apiInstance
        .post(`business/payments-handle/${this.projectKey}/cart-preview/`, payload)
        .then((resp) => CartPreviewResponse.createFromJson(resp.data.data));
    }
    return apiInstance
      .post('business/cart-preview/', payload)
      .then((resp) => CartPreviewResponse.createFromJson(resp.data.data));
  }
}


HydrationHelper.registerType(CartPreviewInput);
HydrationHelper.registerType(BillingAddress);
HydrationHelper.registerType(CartPreviewResponse);
HydrationHelper.registerType(PaymentWizard);

const initState = () => ({
  cartPreviewInput: new CartPreviewInput(),
  cartPreview: null,
  cartPreviewState: new RequestState(),
  cartSaveState: new RequestState(),
  projectSetupState: new RequestState(),
  createProjectState: new RequestState(),
  wizard: new PaymentWizard(),
});

const getters = {};

const actions = {
  prepareProjectSetup({ commit, state, rootState }) {
    commit('updateProjectSetupState', { status: RequestStateStatus.Loading });
    commit('updateCartPreview', { preview: CartPreviewResponse.forProjectSetup() });
    return state.wizard.fetchLicenses(rootState.apiInstance).then((resp) => {
      const { licenses } = resp.data.data;
      commit('wizardUpdateLicenses', { licenses });
      if (!rootState.boot.instance.projects) {
        return state.wizard.projectsList(rootState.apiInstance).then((projectsRaw) => {
          if (projectsRaw && projectsRaw.data && projectsRaw.data.data) {
            const { projects } = projectsRaw.data.data;
            commit('boot/updateProjects', { projects }, { root: true });
          }
          commit('updateProjectSetupState', { status: RequestStateStatus.Loaded });
        });
      }
      return commit('updateProjectSetupState', { status: RequestStateStatus.Loaded });
    }).catch((err) => {
      console.log('Error during project setup ');
      console.error(err);
      commit('updateProjectSetupState', { status: RequestStateStatus.Error, msg: err });
    });
  },

  createProject({ commit, state, rootState }) {
    commit('updateCreateProjectState', { status: RequestStateStatus.Loading });
    return state.wizard.createProject(rootState.apiInstance).then((resp) => {
      const project = resp.data.data;
      commit('resetCreateProject');
      commit('boot/addProject', { project }, { root: true });
      commit('updateCreateProjectState', { status: RequestStateStatus.Loaded });
      return project;
    }).catch((err) => {
      console.log('Error during create prject');
      console.error(err);
      commit('updateCreateProjectState', { status: RequestStateStatus.Error, msg: err });
    });
  },

  fetchCartPreview({ commit, state, rootState }) {
    commit('updateCartPreviewState', { status: RequestStateStatus.Loading });

    return state.cartPreviewInput.fetchResponse(rootState.apiInstance).then((preview) => {
      if (preview.billing) {
        commit('updateCart', { billingKey: preview.billing.key });
      }

      if (preview.billing && preview.billing.billing_currency) {
        const bc = preview.billing.billing_currency;

        if (rootState.user.activeCurrency !== preview.billing.billing_currency) {
          console.log(`Updating local currency ${rootState.user.activeCurrency} as billing currency ${bc}`);
          commit('user/updateActiveCurrency', bc, { root: true });
        }

        if (preview.currency !== bc) {
          preview.currency = bc;
          commit('updateCart', { currency: preview.currency });
        }
      }

      commit('updateCartPreview', { preview });
      commit('updateCartPreviewState', { status: RequestStateStatus.Loaded });
      console.log('Preview Loaded', preview);
    }).catch((err) => {
      console.log('Error during cart preview ');
      console.error(err);
      commit('updateCartPreviewState', { status: RequestStateStatus.Error, msg: err });
    });
  },

  fetchCommittedUseItems({
    commit, state, rootState,
  }, { currency }) {
    return state.wizard.fetchCommittedUseItems(
      rootState.apiInstance, rootState.inMemoryCache, currency,
    ).then((items) => {
      commit('updateCommittedUseItems', { items });
    });
  },

  updateCart({
    commit,
    dispatch,
  }, payload) {
    commit('updateCart', payload);
    if (payload.loadCommitedUseItems) {
      const { currency } = payload;
      return dispatch('fetchCartPreview').then(() => {
        return dispatch('fetchCommittedUseItems', { currency });
      });
    }
    return dispatch('fetchCartPreview');
  },

  createBillingProfile({ commit, state, rootState }) {
    return state.wizard.createBillingProfile(
      rootState.apiInstance, state.cartPreviewInput, state.cartPreview,
    ).then((resp) => {
      commit('updateCart', { billingKey: resp.data.data.key });
      commit('updateCartPreviewParam', { billing: resp.data.data });
      return resp;
    });
  },

  updateBillingProfile({ commit, state, rootState }) {
    return state.wizard.updateBillingProfile(
      rootState.apiInstance, state.cartPreviewInput, state.cartPreview,
    ).then((resp) => {
      commit('updateCartPreviewParam', { billing: resp.data.data });
      return resp;
    });
  },

};

const mutations = {
  updateCartPreviewState(state, { status, msg }) {
    state.cartPreviewState.status = status;
    state.cartPreviewState.ref = msg;
  },

  updateCart(state, {
    projectKey,
    currency,
    couponCode,
    committedUseProduct,
    prepayAmount,
    stateCode,
    prepayAmountKey,
    billingKey,
    invoiceKey,
    quantity,
    projectProductKey,
  }) {
    if (projectKey) {
      state.cartPreviewInput.projectKey = projectKey;
    }

    if (currency) {
      state.cartPreviewInput.updateCurrency(currency);
    }

    if (couponCode) {
      state.cartPreviewInput.couponCode = couponCode;
    }

    if (committedUseProduct || committedUseProduct === null) {
      state.cartPreviewInput.committedUseProduct = committedUseProduct;
    }

    if (quantity) {
      state.cartPreviewInput.quantity = quantity;
    }

    if (stateCode) {
      state.cartPreviewInput.state = stateCode;
    }

    if (prepayAmount !== undefined) {
      state.cartPreviewInput.prepayAmount = prepayAmount;
    }

    if (stateCode) {
      state.cartPreviewInput.state = stateCode;
    }

    if (prepayAmountKey !== undefined) {
      state.cartPreviewInput.prepayAmountKey = prepayAmountKey;
    }

    if (billingKey) {
      state.cartPreviewInput.billingKey = billingKey;
      state.cartPreviewInput.billing.key = billingKey;
    }

    if (invoiceKey) {
      state.cartPreviewInput.invoiceKey = invoiceKey;
      state.wizard.result.invoiceKey = invoiceKey;
    }
    if (projectProductKey) {
      state.cartPreviewInput.project_product_key = projectProductKey;
    }
  },

  updateCartPreview(state, { preview }) {
    Vue.set(state, 'cartPreview', preview);
  },

  updateCartPreviewParam(state, { projectName, selectedLicense, billing }) {
    if (projectName) {
      state.cartPreview.project.name = projectName;
    }

    if (selectedLicense) {
      Vue.set(state.cartPreview.project, 'project_license', [selectedLicense]);
    }

    if (billing) {
      state.cartPreview.billing = billing;
    }
  },

  updateBillingProfile(state, { profile }) {
    Object.keys(profile).forEach((key) => {
      const val = profile[key];
      state.cartPreviewInput.billing[key] = val;
    });
  },

  updateCommittedUseItems(state, { items }) {
    state.wizard.committedUseItems = items;
  },

  updateWizard(state, {
    step, stepRaw, selectedBillingMethod, selectedPaymentMethod,
    projectName, selectedLicense,
  }) {
    if (stepRaw) {
      state.wizard.updateStep(WizardStep.parse(stepRaw));
    }

    if (step) {
      state.wizard.updateStep(step);
    }

    if (selectedBillingMethod) {
      state.wizard.selectedBillingMethod = selectedBillingMethod;
    }

    if (selectedPaymentMethod || selectedPaymentMethod === null) {
      state.wizard.selectedPaymentMethod = selectedPaymentMethod;
    }

    if (projectName !== undefined) {
      state.wizard.givenProjectName = projectName;
    }

    if (selectedLicense !== undefined) {
      state.wizard.selectedLicense = selectedLicense;
    }
  },

  updateWizardResult(state, { props }) {
    Object.keys(props).forEach((key) => {
      const val = props[key];
      state.wizard.result[key] = val;
    });
  },

  updateAddressFromPreview(state) {
    if (state.cartPreview.billing) {
      state.cartPreviewInput.billing = state.cartPreview.billing;
    } else {
      state.cartPreviewInput.billing.billing_currency = state.cartPreview.currency;
    }
  },

  wizardRequestNextStep(state) {
    state.wizard.forward(state.cartPreviewInput);
  },

  wizardMarkComplete(state) {
    state.wizard.markDone();
  },

  wizardUpdateLicenses(state, { licenses }) {
    state.wizard.licenses = licenses;
  },

  updateProjectSetupState(state, { status, msg }) {
    state.projectSetupState.status = status;
    state.projectSetupState.ref = msg;
  },

  updateCreateProjectState(state, { status, msg }) {
    state.createProjectState.status = status;
    state.createProjectState.ref = msg;
  },

  updateCartSaveState(state, { status, msg }) {
    state.cartSaveState.status = status;
    state.cartSaveState.ref = msg;
  },

  resetCreateProject(state) {
    state.wizard = new PaymentWizard();
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
