import Vue from 'vue';
import { SmartMorphAnimationState } from '@/core/view/smartMorph';
import { HydrationHelper } from '@/store/plugins/hydration';
import { BaseType } from './base';

export class LinkStateData extends BaseType {
  constructor(animationState = null, expanded = false, beginCollapse = false, hide = false) {
    super();
    this.animationState = animationState;
    this.expanded = expanded;
    this.beginCollapse = beginCollapse;
    this.hide = hide;
  }

  static typeName() {
    return 'LinkStateData';
  }
}

export class LinkData {
  get haveLinks() {
    return this.linkslist && this.linkslist.links.length;
  }

  get allLinkData() {
    if (this.haveLinks) {
      return this.linkslist.allLinkData;
    }

    return [this];
  }

  get routeParams() {
    // if (this.url) {
    //   return { path: this.url };
    // }

    let params = {};

    if (this.route_value) {
      params.key = this.route_value;
    }

    if (this.route_params) {
      params = { ...params, ...this.route_params };
    }

    if (this.route) {
      return { name: this.route, params };
    }

    return null;
  }

  isCurrentPath(router) {
    return this.isSamePath(router, router.currentRoute.path);
  }

  isSamePath(router, path) {
    const params = this.routeParams;
    if (!params) {
      return false;
    }

    const resolved = router.resolve(params);
    return resolved.resolved.path === path;
  }

  static createFromJson(raw, index = null, key = null, linksListMap = null) {
    if (!linksListMap) {
      linksListMap = {};
    }

    let data = new LinkData();
    data = Object.assign(data, raw);
    data.index = index;
    data.key = key;
    data.state = new LinkStateData();
    if (raw.linkslist_expanded) {
      data.state.expanded = raw.linkslist_expanded;
      data.state.animationState = SmartMorphAnimationState.End;
    }

    data.linkslist = null;
    if (raw.linkslist_key && linksListMap[raw.linkslist_key]) {
      data.linkslist = linksListMap[raw.linkslist_key];
    }

    return data;
  }
}

export class LinksList extends BaseType {
  constructor() {
    super();
    this.links = [];
  }

  get allLinkData() {
    const allLinks = [];
    this.links.forEach((link) => {
      allLinks.push(...link.allLinkData);
    });
    return allLinks;
  }

  static typeName() {
    return 'LinksList';
  }

  static createFromJson(raw, refMap = null) {
    if (!refMap) {
      refMap = {};
    }

    const linksListMap = { ...refMap };
    if (raw.linkslists) {
      Object.keys(raw.linkslists).forEach((key) => {
        linksListMap[key] = LinksList.createFromJson(raw.linkslists[key], linksListMap);
      });
    }
    let data = new LinksList();
    data = Object.assign(data, raw);
    if (raw.links) {
      data.links = raw.links.map((linkRaw, i) => LinkData.createFromJson(linkRaw, i, `${raw.key}.${i}`, linksListMap));
    }
    return data;
  }

  static fetch(key, apiInstance, inMemoryCache) {
    const params = { key };
    const cacheKey = `linkslist_${key}`;
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }

    return apiInstance
      .get('/endpoint/apidocs/linkslist/', { params })
      .then((resp) => {
        const data = LinksList.createFromJson(resp.data.data.linkslist);
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }

  static fetchCricketMaster(apiInstance, inMemoryCache) {
    return LinksList.fetch('cricket/master', apiInstance, inMemoryCache);
  }

  static fetchSportsMaster(apiInstance, inMemoryCache) {
    return LinksList.fetch('sports/master', apiInstance, inMemoryCache);
  }

  static fetchCricketFooter(apiInstance, inMemoryCache) {
    return LinksList.fetch('cricket/footer', apiInstance, inMemoryCache);
  }

  static fetchCricketDocsSenior(apiInstance, inMemoryCache) {
    return LinksList.fetch('cricket/docs/senior', apiInstance, inMemoryCache);
  }

  static fetchDocsGraphQLSenior(apiInstance, inMemoryCache) {
    return LinksList.fetch('cricket/docs/graphQL', apiInstance, inMemoryCache);
  }

  static fetchCricketDocsList(apiInstance, inMemoryCache) {
    return LinksList.fetch('cricket/docs/landing-page', apiInstance, inMemoryCache);
  }

  static fetchCricketRecommendedApis(apiInstance, inMemoryCache) {
    return LinksList.fetch('cricket/docs/core/recommended-apis', apiInstance, inMemoryCache);
  }

  static fetchCricketProducts(apiInstance, inMemoryCache) {
    return LinksList.fetch('cricket/products', apiInstance, inMemoryCache);
  }

  static fetchPaygoMaster(apiInstance, inMemoryCache) {
    return LinksList.fetch('paygo/master', apiInstance, inMemoryCache);
  }

  static fetchRzMenu(apiInstance, inMemoryCache) {
    return LinksList.fetch('rz-website/Menu', apiInstance, inMemoryCache);
  }

  static fetchRzSubMenu(apiInstance, inMemoryCache) {
    return LinksList.fetch('rz/sub-menu/', apiInstance, inMemoryCache);
  }

  static fetchRzFooter(apiInstance, inMemoryCache) {
    return LinksList.fetch('rz/footer', apiInstance, inMemoryCache);
  }

  static fetchRsFooter(apiInstance, inMemoryCache) {
    return LinksList.fetch('rs/footer', apiInstance, inMemoryCache);
  }

  static fetchRsDiscountPromo(apiInstance, inMemoryCache) {
    return LinksList.fetch('cricket/sticky-discount', apiInstance, inMemoryCache);
  }
}

HydrationHelper.registerType(LinksList);
HydrationHelper.registerType(LinkStateData);

const initState = () => ({
  cricketMaster: new LinksList(),
  sportsMaster: new LinksList(),
  cricketFooter: new LinksList(),
  cricketFooterTOC: new LinksList(),
  cricketDocsSenior: new LinksList(),
  seniorMenuState: new LinkStateData(),
  cricketRecommendedApis: new LinksList(),
  cricketProducts: new LinksList(),
  paygoMaster: new LinksList(),
  rzMenu: new LinksList(),
  rzSubMenu: new LinksList(),
  rzFooterLinks: new LinksList(),
  rsFooterLinks: new LinksList(),
  rsDiscountPromo: new LinksList(),
});

const getters = {};

const actions = {
  fetchSportsMaster({ commit, rootState }) {
    return LinksList.fetchSportsMaster(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateSportsMaster', { data });
    });
  },
  fetchCricketMaster({ commit, rootState }) {
    return LinksList.fetchCricketMaster(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateCricketMaster', { data });
    });
  },
  fetchCricketFooter({ commit, rootState }) {
    return LinksList.fetchCricketFooter(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateCricketFooter', { data });
    });
  },
  fetchRzFooter({ commit, rootState }) {
    return LinksList.fetchRzFooter(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateRzFooter', { data });
    });
  },
  fetchRsFooter({ commit, rootState }) {
    return LinksList.fetchRsFooter(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateRsFooter', { data });
    });
  },
  fetchRsDiscountPromo({ commit, rootState }) {
    return LinksList.fetchRsDiscountPromo(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateRsDiscountPromo', { data });
    });
  },
  fetchCricketDocsSenior({ commit, rootState }) {
    return LinksList.fetchCricketDocsSenior(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateCricketDocsSenior', { data });
    });
  },
  fetchDocsGraphQLSenior({ commit, rootState }) {
    return LinksList.fetchDocsGraphQLSenior(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateDocsGraphQLSenior', { data });
    });
  },
  fetchCricketRecommendedApis({ commit, rootState }) {
    return LinksList.fetchCricketRecommendedApis(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateCricketRecommendedApis', { data });
    });
  },
  fetchCricketProducts({ commit, rootState }) {
    return LinksList.fetchCricketProducts(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateCricketProducts', { data });
    });
  },
  fetchPaygoMaster({ commit, rootState }) {
    return LinksList.fetchPaygoMaster(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updatePaygoMaster', { data });
    });
  },
  fetchRzMenu({ commit, rootState }) {
    return LinksList.fetchRzMenu(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateRzMenu', { data });
    });
  },
  fetchRzSubMenu({ commit, rootState }) {
    return LinksList.fetchRzSubMenu(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updateRzSubMenu', { data });
    });
  },
};

const mutations = {
  updateSportsMaster(state, { data }) {
    Vue.set(state, 'sportsMaster', data);
  },

  updateCricketMaster(state, { data }) {
    Vue.set(state, 'cricketMaster', data);
  },

  updatePaygoMaster(state, { data }) {
    Vue.set(state, 'paygoMaster', data);
  },

  updateCricketFooter(state, { data }) {
    let toc = null;
    const links = [...data.links];
    links.forEach((link, i) => {
      if (link.element_id === 'toc') {
        toc = link;
        links.pop(i);
      }
    });

    Vue.set(state, 'cricketFooter', links);
    Vue.set(state, 'cricketFooterTOC', toc);
  },

  updateRzFooter(state, { data }) {
    let tools = null;
    data.links.forEach((link, i) => {
      if (link.element_id === 'tools') {
        tools = link;
        data.links.pop(i);
      }
    });
    data.tools = tools;
    data.loaded = true;
    Vue.set(state, 'rzFooterLinks', data);
  },

  updateRsFooter(state, { data }) {
    data.loaded = true;
    Vue.set(state, 'rsFooterLinks', data);
  },

  updateRsDiscountPromo(state, { data }) {
    Vue.set(state, 'rsDiscountPromo', data);
  },

  updateCricketDocsSenior(state, { data }) {
    Vue.set(state, 'cricketDocsSenior', data);
  },

  updateDocsGraphQLSenior(state, { data }) {
    Vue.set(state, 'docsGraphQLSenior', data);
  },

  updateMasterLinkState(state, newState) {
    const linkData = state.cricketMaster.links[newState.index];
    if (linkData) {
      linkData.state = { ...linkData.state, ...newState };
    }
  },

  updateSeniorMenuState(state, newState) {
    Object.keys(newState).forEach((key) => {
      state.seniorMenuState[key] = newState[key];
    });
  },

  updateCricketRecommendedApis(state, { data }) {
    Vue.set(state, 'cricketRecommendedApis', data);
  },

  updateCricketProducts(state, { data }) {
    Vue.set(state, 'cricketProducts', data);
  },

  updateRzMenu(state, { data }) {
    Vue.set(state, 'rzMenu', data);
  },

  updateRzSubMenu(state, { data }) {
    Vue.set(state, 'rzSubMenu', data);
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
