import Vue from 'vue';
import { HydrationHelper } from '@/store/plugins/hydration';
import { RequestState, RequestStateStatus } from '@/api/core';
import { Enum } from '@/core/utils/enum';
import { WebPage } from './page';

export const Authors = new Enum({
  Ajai_Kannan: 'ajai_kannan',
  Divya_Gurunath: 'divya_gurunath',
  Goutham: 'goutham',
  Nooreash: 'nooreash',
  Anto: 'anto',
  Arun: 'arun',
  Abhinaya: 'Abhinaya',
});

export class BlogList extends WebPage {
  constructor() {
    super();
    this.loaded = false;
  }

  static createFromJson(raw) {
    let data = new BlogList();
    data = Object.assign(data, raw);
    return data;
  }

  static fetchBlogList(apiInstance, inMemoryCache, params, routeName) {
    let cacheKey = 'bloglist';
    if (params.limit) {
      cacheKey += `_${params.limit}`;
    }
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }
    // eslint-disable-next-line no-underscore-dangle
    params._usefresh = 'random-123';
    return apiInstance
      .get('/endpoint/blog/list/', {
        params,
      })
      .then((resp) => {
        const blogList = BlogList.createFromJson(resp.data.data);
        blogList.blogs.forEach((blog) => {
          blog.author_keys.forEach((author, index) => {
            blog.author_keys[index] = Authors.parse(author);
          });
        });
        blogList.routeValue = routeName;
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = blogList;
        }
        return blogList;
      });
  }
}

HydrationHelper.registerType(BlogList);

const initState = () => ({
  activeBlogList: new WebPage(),
  activeBlogState: new RequestState(),
  activeBlogListState: new RequestState(),
});

const getters = {};

const actions = {
  fetchBlogList({ commit, rootState }, { params, routeName }) {
    commit('updateActiveBlogListStatus', { status: RequestStateStatus.Loading });
    return new Promise((resolve) => {
      BlogList.fetchBlogList(rootState.apiInstance, rootState.inMemoryCache, params, routeName).then((data) => {
        commit('updateActiveBlogList', data);
        commit('updateActiveBlogListStatus', { status: RequestStateStatus.Loaded });
        resolve(data);
      }).catch((err) => {
        console.log('Error while fetching blog page list ');
        commit('updateActiveBlogListStatus', { status: RequestStateStatus.Error, msg: err });
      });
    });
  },
};

const mutations = {
  updateActiveBlogList(state, data) {
    Vue.set(state, 'activeBlogList', data);
  },
  updateActiveBlogListStatus(state, { status, msg }) {
    state.activeBlogListState.status = status;
    state.activeBlogListState.ref = msg;
  },
  resetActivePage(state) {
    Vue.set(state, 'activeBlogList', new WebPage());
    Vue.set(state, 'activeBlogListState', new RequestState());
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
