// const timeTravel = [];
// export const hydrationHelper = (store) => {
//   store.subscribe(({ type, payload }) => {
//     console.log(type, payload);
//     timeTravel.push(type, JSON.stringify(payload));
//   });
// };

let hydrationHelperInstance = null;

export const HydrationHelper = {
  instance() {
    if (hydrationHelperInstance === null) {
      hydrationHelperInstance = {
        typeMaps: {},
      };
    }

    return hydrationHelperInstance;
  },

  registerType(typ) {
    // Its best to get the name from constructor but
    // the minified file does not carry the same name
    // https://github.com/webpack/webpack/tree/master/examples/scope-hoisting

    const maps = HydrationHelper.instance().typeMaps;
    const name = typ.typeName();
    if (maps[name]) {
      console.log(`${name} already registered`);
    } else {
      maps[name] = typ;
    }
  },

  parseType(raw) {
    if (raw && raw.rzTypeName) {
      const typeName = raw.rzTypeName;
      const maps = HydrationHelper.instance().typeMaps;
      if (maps[typeName]) {
        return maps[typeName].fromHydration(raw);
      }

      console.log(`Unregistered type ${typeName}`);
    }
    return raw;
  },

  parseRawState(rawState) {
    const rawStr = JSON.stringify(rawState);
    const newState = JSON.parse(rawStr, (key, value) => HydrationHelper.parseType(value));
    return newState;
  },
};
