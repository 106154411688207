import anime from 'animejs';
import { Enum } from '@/core/utils/enum';

export const SmartMorphAnimationState = new Enum({
  Init: 'init',
  End: 'end',
});

export class SmartMorph {
  constructor({
    rootElement, frameIdPrefix, unsetStroke = 'black', unsetFill = 'black',
    preBornElementAttrs = null, bornElementAttrs = null,
    dyingElementAttrs = null, deadElementAttrs = null,
    state = null, onStateChange = null, includeFirstStep = false,
  } = {}) {
    this.rootElement = rootElement;
    this.frameIdPrefix = frameIdPrefix;
    this.unsetStroke = unsetStroke;
    this.unsetFill = unsetFill;

    // Style for element not in current frame but in next
    this.preBornElementAttrs = preBornElementAttrs || { opacity: 0.0 };

    // Style for element not in previous but in current frame
    this.bornElementAttrs = bornElementAttrs || { opacity: 1.0 };

    // Style for element not in next frame but in current
    this.dyingElementAttrs = dyingElementAttrs || { opacity: 1.0 };

    // Style for element not in current but in prev frame
    this.deadElementAttrs = deadElementAttrs || { opacity: 0.0 };

    this.frames = [];

    this.ignoreAttr = ['id', 'class', 'style'];

    this.timelineDataAttrs = [];

    this.idMap = {};

    this.animation = null;
    this.animationState = state || SmartMorphAnimationState.Init;

    this.onStateChangeCallback = onStateChange;
    this.includeFirstStep = includeFirstStep;
  }

  setup() {
    this.cleanup();
    this.createFrames();
  }

  getFrameElement(frame) {
    return this.rootElement.querySelector(`[rz-id=${frame.rzId}]`);
  }

  getElement(ele, id) {
    return ele.querySelector(`[rz-id=${this.idMap[id]}]`);
  }

  getAnimationElement(id) {
    const firstElement = this.getFrameElement(this.frames[0]);
    return this.getElement(firstElement, id);
  }

  createFrames() {
    this.rootElement.querySelectorAll('[id]').forEach((element) => {
      const id = element.getAttribute('id');

      // Use normalised ID to avoid invalid character query selector
      let rzId = this.idMap[id];
      if (!rzId) {
        const l = Object.keys(this.idMap).length + 1;
        rzId = `i${l}`;
        this.idMap[id] = rzId;
      }
      element.setAttribute('rz-id', rzId);

      // Identify Frames
      if (id.startsWith(this.frameIdPrefix)) {
        const givenIndex = parseInt(id.replace(this.frameIdPrefix, ''), 10);
        if (!Number.isNaN(givenIndex)) {
          this.frames.push({
            givenIndex, id, rzId, data: {}, index: -1,
          });
        } else {
          console.log(`Ignoring ${id} from frames`);
        }
      }
    });

    this.frames.sort((a, b) => a.givenIndex - b.givenIndex);
    this.frames.forEach((frame, i) => {
      frame.index = i;
      const ele = this.getFrameElement(frame);
      ele.setAttribute('opacity', (i > 0) ? 0 : 1);
    });
    // console.log('Frames', this.frames);
  }

  createAnimationData() {
    if (this.frames.length === 0) {
      return;
    }

    let tracableIds = [];
    let firstFrameEle = null;

    // Identify Dead & New
    this.frames.forEach((frame, i) => {
      const ele = this.getFrameElement(frame);
      const ids = [...ele.querySelectorAll('[id]')].map((x) => x.id);

      if (i === 0) {
        tracableIds = [...ids];
        firstFrameEle = ele;
      } else if (i > 0) {
        const newIds = ids.filter((x) => !tracableIds.includes(x));
        const deadIds = tracableIds.filter((x) => !ids.includes(x));

        newIds.forEach((newId) => {
          const newEle = this.getElement(ele, newId).cloneNode();
          newEle.setAttribute('rz-born-at', i);
          firstFrameEle.append(newEle);
          tracableIds.push(newId);
        });

        deadIds.forEach((deadId) => {
          const deadEle = this.getElement(firstFrameEle, deadId);
          if (!deadEle.hasAttribute('rz-dead-at')) {
            deadEle.setAttribute('rz-dead-at', i);
          }
        });
      }
    });

    const dataAttr = {};
    firstFrameEle.querySelectorAll('[id]').forEach((traceEle) => {
      const bornAt = parseInt(traceEle.getAttribute('rz-born-at') || 0, 10);
      const deadAt = parseInt(traceEle.getAttribute('rz-dead-at') || this.frames.length, 10);
      const hasBorn = bornAt > 0;
      const hasDead = deadAt < this.frames.length;
      const traceEleId = traceEle.id;
      const knownAttrs = {};
      let lastFrameAttrs = {};

      if (hasDead) {
        Object.assign(knownAttrs, this.deadElementAttrs);
      }

      if (hasBorn) {
        Object.assign(knownAttrs, this.preBornElementAttrs);
        Object.keys(this.preBornElementAttrs).forEach((key) => {
          traceEle.setAttribute(key, this.preBornElementAttrs[key]);
        });
      }

      Object.assign(knownAttrs, this.getAttrs(traceEle));
      const knowAttrKeys = Object.keys(knownAttrs);
      const attrsValues = {};

      knowAttrKeys.forEach((key) => {
        if (this.includeFirstStep) {
          attrsValues[key] = { values: [knowAttrKeys[key]] };
        } else {
          attrsValues[key] = { values: [] };
        }
      });

      this.frames.forEach((frame, i) => {
        if (i > 0) {
          const frameEle = this.getFrameElement(frame);
          const frameTraceEle = this.getElement(frameEle, traceEleId);
          // console.log('E', traceEleId, frameTraceEle);
          const frameAttrs = {};

          if (i < bornAt) {
            Object.assign(frameAttrs, knownAttrs);
          }

          if (i >= deadAt) {
            Object.assign(frameAttrs, lastFrameAttrs);
          }

          if (frameTraceEle) {
            Object.assign(frameAttrs, this.getAttrs(frameTraceEle));
          }

          if (i < bornAt) {
            Object.assign(frameAttrs, this.preBornElementAttrs);
          } else if (i === bornAt) {
            Object.assign(frameAttrs, this.bornElementAttrs);
          }

          if (hasDead) {
            if (i >= deadAt) {
              Object.assign(frameAttrs, this.deadElementAttrs);
            } else if (i === (deadAt - 1)) {
              Object.assign(frameAttrs, this.dyingElementAttrs);
            }
          }

          if (i < deadAt) {
            lastFrameAttrs = frameAttrs;
          }

          const frameAttrsKeys = Object.keys(frameAttrs);
          frameAttrsKeys.forEach((key) => {
            if (!knowAttrKeys.includes(key)) {
              // New Attribute
              attrsValues[key] = { values: [] };
              knowAttrKeys.push(key);
              for (let fi = 1; fi < i; fi += 1) {
                attrsValues[key].values.push(this.defaultNewValue(key));
              }
            }

            attrsValues[key].values.push(frameAttrs[key]);
          });

          const deadAttrs = knowAttrKeys.filter((x) => !frameAttrsKeys.includes(x));
          deadAttrs.forEach((key) => {
            attrsValues[key].values.push(this.defaultDeadValue(key));
          });
        }
      });

      dataAttr[traceEleId] = attrsValues;
    });

    this.timelineDataAttrs = dataAttr;
    // console.table(JSON.parse(JSON.stringify(this.timelineDataAttrs)));
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  defaultNewValue(attr) {
    return 0;
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  defaultDeadValue(attr) {
    return 0;
  }

  getAttrs(ele) {
    const attrPairs = ele.attributes;
    const attrs = {};
    for (let i = attrPairs.length - 1; i >= 0; i -= 1) {
      const { name, value } = attrPairs[i];
      const startswith = (!name.startsWith('rz-')) && (!name.startsWith('data-'));
      if ((!this.ignoreAttr.includes(name)) && startswith) {
        attrs[name] = value;
      }
    }

    return attrs;
  }

  cleanup() {
    const unsets = [];
    if (this.unsetFill) {
      unsets.push('fill');
    }

    if (this.unsetStroke) {
      unsets.push('stroke');
    }

    unsets.forEach((attribute) => {
      this.rootElement.querySelectorAll(`[${attribute}]`).forEach((element) => {
        element.removeAttribute(attribute);
        const className = `default-${attribute}`;
        const oldClass = element.getAttribute('class') || '';
        element.setAttribute('class', `${oldClass} ${className}`);
      });
    });
  }

  createAnimationTimeline() {
    this.animation = anime.timeline({
      // easing: 'linear',
      easing: 'easeInOutExpo',
      // easing: 'easeOutElastic(1, 0.5)',
      duration: 750,
      // baseFrequency: 2,
      // delay: (el, i) => i * 200,
      autoplay: false,
    });

    Object.keys(this.timelineDataAttrs).forEach((eleId) => {
      const animationData = {
        targets: this.getAnimationElement(eleId),
      };

      Object.keys(this.timelineDataAttrs[eleId]).forEach((attr) => {
        animationData[attr] = this.timelineDataAttrs[eleId][attr].values;
      });

      this.animation.add(animationData, 0);
    });

    if (this.animationState.isEnd) {
      this.gotoFrame(this.frames.length);
    }
  }

  gotoFrame(step) {
    this.seek((step / this.frames.length) * 100);
  }

  animateToEnd() {
    if (this.animation.reversed) {
      this.animation.reverse();

      // Fix for Bug that prevent seek.
      setTimeout(() => {
        this.animation.reset();
        this.animateToEnd();
      }, 1);
      return;
    }

    if (this.includeFirstStep) {
      this.gotoFrame(1);
    }
    this.animation.play();
    this.updateStateValue(SmartMorphAnimationState.End);
  }

  animateToBegin() {
    if (this.includeFirstStep) {
      this.gotoFrame(this.frames.length);
    }
    if (!this.animation.reversed) {
      this.animation.reverse();
    }
    this.animation.play();
    this.updateStateValue(SmartMorphAnimationState.Init);
  }

  toggleAnimation() {
    if (!this.animation) {
      this.createAnimationTimeline();
    }
    if (this.animationState.isInit) {
      this.animateToEnd();
    } else {
      this.animateToBegin();
    }

    // if (this.animation) {
    //   this.animation.reverse();
    //   this.animation.play();
    //   this.updateStateValue(
    //     (this.animationState.isInit) ? SmartMorphAnimationState.End : SmartMorphAnimationState.Init);
    // } else {
    //   this.createAnimationTimeline();
    //   this.animation.play();
    //   this.updateStateValue(SmartMorphAnimationState.End);
    // }
  }

  updateStateValue(state) {
    this.animationState = state;
    if (this.onStateChangeCallback) {
      this.onStateChangeCallback(state);
    }
  }

  changeState(state) {
    if (!this.animationState.is(state)) {
      this.toggleAnimation();
    }
  }

  seek(seekPercent) {
    if (!this.animation) {
      this.createAnimationTimeline();
    }

    // this.animation.pause();
    const val = parseFloat(seekPercent);
    this.animation.seek(this.animation.duration * (val / 100));
  }
}
