import { sync } from 'vuex-router-sync';
import Vue from 'vue';
import moment from 'moment';

import { API } from '@/api/core';
import {
  formatCurrency,
  formatCurrencyWithDiscount,
  replaceCurrencyOneUnit,
  formatBlogPublicationDate,
} from '@/core/utils/helper';
import App from './App.vue';
import { createRouter } from './router';
import { createStore } from './store';

import RequestState from './components/RequestState.vue';
import ImageItem from './components/ImageItem.vue';
import LinkItem from './components/LinkItem.vue';
import Markdown from './components/Markdown.vue';
import JSONPath from './components/CodeDisplay/JSONPath.vue';

export const prepareApp = (restoreState, apiInstance, context, reject) => {
  Vue.config.productionTip = true;

  // create router and store instances
  const store = createStore();
  if (restoreState) {
    store.replaceState(restoreState);
  }

  let inMemoryCache = null;
  if (context && context.inMemoryCache) {
    inMemoryCache = context.inMemoryCache;
  }

  store.commit('updateApiInstance', { apiInstance });
  store.commit('updateInMemoryCache', { inMemoryCache });

  const router = createRouter(apiInstance, context, reject, store);

  sync(store, router);

  Vue.component('rz-request-state', RequestState);
  Vue.component('rz-image-item', ImageItem);
  Vue.component('rz-link-item', LinkItem);
  Vue.component('rz-markdown', Markdown);
  Vue.component('rz-json-path', JSONPath);

  return { router, store };
};

export const createApp = (restoreState, context, reject) => {
  let apiHeaders = {};
  let gtDataLayer = [];
  if (context && context.ssr) {
    apiHeaders = { ...apiHeaders, ...context.ssr.headers };
  } else {
    gtDataLayer = window.dataLayer;
  }
  const apiInstance = API.createInstance(apiHeaders);
  const { store, router } = prepareApp(restoreState, apiInstance, context, reject);

  let inMemoryCache = null;
  if (context && context.inMemoryCache) {
    inMemoryCache = context.inMemoryCache;
  }

  Vue.prototype.$apiInstance = apiInstance;
  Vue.prototype.$inMemoryCache = inMemoryCache;

  const app = new Vue({
    data: { url: context ? context.url : '' },
    gtDataLayer,
    router,
    store,
    render: (h) => h(App),
  });

  return { app, router, store };
};

Vue.mixin({
  data() {
    return {
      formatCurrency(amount, currency) {
        return formatCurrency(amount, currency);
      },
      formatCurrencyWithDiscount(amount, currency, discount) {
        return formatCurrencyWithDiscount(amount, currency, discount);
      },
      replaceCurrencyOneUnit(message, currency) {
        return replaceCurrencyOneUnit(message, currency);
      },
      toDate(value) {
        return moment.unix(value).format('MMM DD YYYY');
      },
      addMonth(value, month = 12) {
        const calculatedDate = moment.unix(value);
        const addedDate = calculatedDate.add(month, 'month').format('MMM DD YYYY');
        return addedDate;
      },
      formatBlogPublicationDate(date) {
        return formatBlogPublicationDate(date);
      },
    };
  },
});
