<template>
  <div class="link-item" :class="[link.element_class]">
    <router-link
      class="link-router"
      :class="[linkClass]"
      v-if="link.routeParams"
      :to="link.routeParams"
      :exact-active-class="'active'">
      <slot>{{link.name}}</slot>
    </router-link>
    <a class="link-plain"
      :class="[linkClass]" :href="link.url"
    :target="link.target"
    v-else-if="link.url">
      <slot>{{link.name}}</slot>
    </a>
    <span v-else class="link-placeholder">
      <slot>{{link.name}}</slot>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    link: {
      type: Object,
    },
    linkClass: {
      type: String,
      default: '',
    },
  },
};
</script>
