/* eslint-disable no-useless-escape */
/* eslint-disable prefer-template */
const CookieManager = {
  getItem(sKey) {
    if (!sKey) {
      return null;
    }
    const parsedKey = encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&');
    const regx = new RegExp('(?:(?:^|.*;)\\s*' + parsedKey + '\\s*\\=\\s*([^;]*).*$)|^.*$');
    return decodeURIComponent(document.cookie.replace(regx, '$1')) || null;
  },
  setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
    let sExpires = '';
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : '; max-age=' + vEnd;
          break;
        case String:
          sExpires = '; expires=' + vEnd;
          break;
        case Date:
          sExpires = '; expires=' + vEnd.toUTCString();
          break;
        default:
          break;
      }
    }
    document.cookie = encodeURIComponent(sKey) + '='
    + encodeURIComponent(sValue) + sExpires + (sDomain ? '; domain=' + sDomain : '')
    + (sPath ? '; path=' + sPath : '') + (bSecure ? '; secure' : '');
    return true;
  },
  removeItem(sKey, sPath, sDomain) {
    if (!this.hasItem(sKey)) { return false; }
    document.cookie = encodeURIComponent(sKey) + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT'
    + (sDomain ? '; domain=' + sDomain : '') + (sPath ? '; path=' + sPath : '');
    return true;
  },
  hasItem(sKey) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return false; }
    return (new RegExp('(?:^|;\\s*)'
    + encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') + '\\s*\\=')).test(document.cookie);
  },
  keys() {
    const replaceRegex = /((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g;
    const aKeys = document.cookie.replace(replaceRegex, '').split(/\s*(?:\=[^;]*)?;\s*/);
    aKeys.forEach((nIdx) => {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx]);
    });
    return aKeys;
  },
  clear(sPath, sDomain) {
    const aKeys = this.keys();
    aKeys.forEach((sKey) => {
      this.removeItem(sKey, sPath, sDomain);
    });
  },
};

export default CookieManager;
