import Vue from 'vue';
import { HydrationHelper } from '@/store/plugins/hydration';
import { BaseType } from './base';
import { User } from './user';

export class BootData extends BaseType {
  static createFromJson(raw) {
    let data = new BootData();
    data = Object.assign(data, raw);
    if (data.user) {
      data.user = User.createFromJson(data.user);
    }
    return data;
  }

  static typeName() {
    return 'BootData';
  }

  static fetch(apiInstance) {
    return apiInstance.get('/boot/').then((resp) => BootData.createFromJson(resp.data.data));
  }
}

HydrationHelper.registerType(BootData);

const initState = () => ({
  instance: null,
  appCreated: false,
  appMounted: false,
  appDataReady: false,
  loginRequired: false,
  loginParams: {},
});

const getters = {};

const actions = {
  getInstance({ dispatch, state }) {
    if (state.instance) {
      return Promise.resolve(state.instance);
    }

    return dispatch('fetchInstance');
  },

  fetchInstance({ commit, rootState }) {
    console.log('Boot Loading...');
    return BootData.fetch(rootState.apiInstance).then((data) => {
      commit('updateInstance', { data });
    });
  },
};

const mutations = {
  updateInstance(state, { data }) {
    if (data.user) {
      data.user.syncLocalUserData();
    }
    Vue.set(state, 'instance', data);
  },

  updateUser(state, { user }) {
    user.syncLocalUserData();
    state.instance.user = user;
  },
  syncUserData(state) {
    state.instance.user.syncLocalUserData();
  },
  markAppCreated(state) {
    state.appCreated = true;
  },
  markAppMounted(state) {
    state.appMounted = true;
  },
  markAppDataReady(state) {
    state.appDataReady = true;
  },
  updateLoginRequired(state, { params }) {
    state.loginRequired = true;
    state.loginParams.params = params;
  },
  revokeLoginRequired(state) {
    state.loginRequired = false;
    state.loginParams.params = null;
  },
  updateProjects(state, { projects }) {
    state.instance.projects = projects;
  },
  addProject(state, { project }) {
    state.instance.projects.push(project);
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
