import Vue from 'vue';
import { HydrationHelper } from '@/store/plugins/hydration';
import { RequestStateStatus } from '@/api/core';
import { Enum } from '@/core/utils/enum';
import { FieldHandler } from '@/core/utils/formModels';

import { BaseType } from './base';

export const PaymentMethods = new Enum({
  Invoice: 'invoice',
  PayPal: 'paypal',
  Razorpay: 'razorpay',
  Stripe: 'stripe',
});

export const SuccessState = new Enum({
  Payment: 'payment',
  FreeSports: 'free_sports',
  CardSaved: 'card_saved',
  Billing: 'billing',
  Others: 'others',
});

export const licenseTypes = {
  standard: 'rs_paygo_standard',
  enterprisea101: 'rs_paygo_enterprise_a101',
  enterprisec1x: 'rs_paygo_enterprise_c1x',
  premium: 'rs_paygo_premium',
};

export const Currencies = new Enum({
  INR: {
    value: 'inr',
    label: 'India',
    symbol: '₹',
  },
  USD: {
    value: 'usd',
    label: 'International',
    symbol: '$',
  },
  EUR: {
    value: 'eur',
    label: 'Europe',
    symbol: '€',
  },
});

/* eslint-disable no-underscore-dangle */

export const Endpoints = new Enum({
  Match: 'match',
  Association: 'association',
  Tournament: 'tournament',
  Performance: 'performance',
  Fantasy: 'fantasy',
  Chatbot: 'chatbot',
  Auth: 'auth',
});

export class PaymentInfo {
  constructor() {
    this.upFrontProduct = null;
    this.upFrontAmount = 0;
    this.prePaidProduct = null;
    this.prePayAmount = 0;
    this.offerAmount = 0;
    this.subTotalPay = 0;
    this.paymentMedium = 'invoice';
    this.paymentGateway = PaymentMethods.Invoice.value;
    this.currency = Currencies.USD.value.value;
  }

  static getOfferPercentage(regular, extra) {
    const percent = (extra / (regular + extra)) * 100;
    console.log('regular', regular, extra, percent);
    return `${parseInt(percent, 10)}%`;
  }
}

export class BillingProfile {
  constructor(email, name, project) {
    this.key = null;
    this.company_name = null;
    this.city = null;
    this.contact_email = email || null;
    this.contact_name = name || null;
    this.state = null;
    this.zip_code = null;
    this.address = null;
    this.billing_currency = null;
    this.country = null;
    this.country_code = null;
    this.user_key = null;
    this.project = project || null;
    this.gst_number = null;
    this.action = 'update_billing';
  }

  static createFromJson(raw) {
    let data = new BillingProfile();
    data = Object.assign(data, raw);
    return data;
  }

  static billingAction(apiInstance, data) {
    console.log('data', data);
    return apiInstance
      .post('/business/user/billing/actions/', data)
      .then((resp) => resp.data.data);
  }

  static billingAddress(apiInstance) {
    return apiInstance
      .get('/business/user/billing/')
      .then((resp) => resp.data.data);
  }
}

export class BillingFields {
  constructor() {
    this.company_name = 'Company Name can\'t be blank';
    this.city = 'City can\'t be blank';
    this.contact_email = 'Enter Valid Email Address';
    this.contact_name = 'Name can\'t be blank';
    this.state = 'State can\'t be blank';
    this.zip_code = 'Enter Valid Zip Code';
    this.address = 'Address can\'t be blank';
    this.billing_currency = 'Select Valid Currency';
    this.country = 'Country can\'t be blank';
    this.gst_number = 'Enter Valid GST number';
  }

  validateBillInfo(billInfo, isBusiness = false) {
    let count = 0;
    Object.keys(billInfo).forEach((attr) => {
      if (['gst_number', 'company_name'].includes(attr) && !isBusiness) {
        console.log('came');
      } else if (this[attr]) {
        if (!this[attr].validateField(billInfo[attr])) {
          count += 1;
        } else if (attr === 'billing_currency') {
          if ((
            (billInfo.billing_currency !== 'inr') && (billInfo.country_code && billInfo.country_code === 'IN'))
            || (
              (billInfo.billing_currency === 'inr') && (billInfo.country_code && billInfo.country_code !== 'IN')
            )) {
            // eslint-disable-next-line max-len
            this.billing_currency.errorMsg = 'If you want to choose country as INDIA you need to edit the billing currency!';
            this.billing_currency.showError = true;
            count += 1;
          } else {
            this.billing_currency.showError = false;
          }
        }
      }
    });
    return (count === 0);
  }

  static createFromJson() {
    const data = new BillingFields();
    Object.keys(data).forEach((attr) => {
      data[attr] = new FieldHandler({
        errorMsg: data[attr],
      });
    });
    return data;
  }
}

export class OrderInfo {
  constructor() {
    this.key = null;
    this.currency = Currencies.USD.value.value;
    this.grand_total = 0;
  }

  static createFromJson(raw) {
    let data = new OrderInfo();
    data = Object.assign(data, raw);
    if (raw?.billing) {
      data.billing = BillingProfile.createFromJson(raw.billing);
    }
    return data;
  }

  static preparePayment(apiInstance, data) {
    console.log('data', data);
    return apiInstance
      .post('/business/create_payment/', data)
      .then((resp) => resp.data.data);
  }

  static confirmPayment(apiInstance, transactionKey) {
    return apiInstance
      .post(`/business/confirm_payment/${transactionKey}/`, {})
      .then((resp) => resp.data);
  }
}

export class PayGoResources extends BaseType {
  constructor() {
    super();
    this.licenseStatus = RequestStateStatus.NotInit;
    this.upfrontStatus = RequestStateStatus.NotInit;
    this.resourceStatus = RequestStateStatus.NotInit;
    this.licenses = null;
    this.upfrontOffers = null;
    this.resources = null;
    this.prePaidAmounts = null;
    this.ref = null;
    this.msg = null;
  }

  static getLicenses(apiInstance) {
    return apiInstance
      .get('/business/licenses/?version=v2')
      .then((resp) => resp.data.data);
  }

  static getUpFronts(apiInstance, inMemoryCache, currency) {
    const cacheKey = `cricket_upfronts_raw_${currency}`;
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }
    return apiInstance
      .get(`/business/upfronts/?sort_currency=${currency}&sport=cricket`)
      .then((resp) => {
        const { data } = resp.data;
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }

  static getPrepayAmounts(apiInstance) {
    return apiInstance
      .get('/business/prepaid_payments/')
      .then((resp) => resp.data.data);
  }

  static getResourcePricing(apiInstance, inMemoryCache) {
    const cacheKey = 'cricket_resurces';
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }

    return apiInstance
      .get('/business/cricket/resources/?sport=cricket&version=v2')
      .then((resp) => {
        const data = PayGoResources.createFromJson(resp.data.data);
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }

  static getResourcesInfo(apiInstance, inMemoryCache) {
    const cacheKey = 'cricket_resurces_raw';
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }
    return apiInstance
      .get('/business/cricket/resources/?sport=cricket&version=v2')
      .then((resp) => {
        const { data } = resp.data;
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }

  static getGraphqlResourcePricing(apiInstance, inMemoryCache) {
    const cacheKey = 'cricket_resurces';
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }

    return apiInstance
      .get('/business/cricket/graphql/resources/?sport=cricket&version=v2')
      .then((resp) => {
        const data = PayGoResources.createFromJson(resp.data.data);
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }

  static createFromJson(raw) {
    const groupByEndpoints = {};
    let usageInfo = null;
    const volumeDiscount = raw.matches_volume_discount;
    if (raw) {
      usageInfo = raw.usage;
      if (raw.resources) {
        const resourceKeys = Object.keys(raw.resources);
        resourceKeys.forEach((resourceKey) => {
          const resource = raw.resources[resourceKey];
          resource.resourceKey = resourceKey;
          let endPoint = resource.endpoint_group;
          if (endPoint === null) {
            endPoint = 'auth';
          }
          if (!groupByEndpoints[endPoint]) {
            groupByEndpoints[endPoint] = [];
          }
          groupByEndpoints[endPoint].expand = true;
          groupByEndpoints[endPoint].push(resource);
        });
      }
    }
    return { groupByEndpoints, usageInfo, volumeDiscount };
  }

  static typeName() {
    return 'PayGoResources';
  }
}

export class Package extends BaseType {
  constructor() {
    super();
    this.packageStatus = RequestStateStatus.NotInit;
    this.packageInfo = null;
    this.ref = null;
  }

  static getPackagePricing(apiInstance, inMemoryCache) {
    const cacheKey = 'cricket_package';
    if (inMemoryCache && inMemoryCache[cacheKey]) {
      return Promise.resolve(inMemoryCache[cacheKey]);
    }

    return apiInstance
      .get('/business/products/package_products/list/?show_active=1&show_visible=1&show_all=0')
      .then((resp) => {
        const data = resp;
        if (inMemoryCache) {
          inMemoryCache[cacheKey] = data;
        }
        return data;
      });
  }

  static typeName() {
    return 'Package';
  }
}

HydrationHelper.registerType(PayGoResources);
HydrationHelper.registerType(Package);

const initState = () => ({
  payGoResources: new PayGoResources(),
  package: new Package(),
});

const getters = {};

const actions = {
  fetchLicenses({ commit, rootState }) {
    commit('updateLicenseStatus', { status: RequestStateStatus.Loading });

    return PayGoResources.getLicenses(rootState.apiInstance).then((data) => {
      commit('updateLicenses', { data });
      commit('updateLicenseStatus', { status: RequestStateStatus.Loaded });
    }).catch((err) => {
      // console.error(err);
      console.log('Error while fetch licenses ');
      commit('updateLicenseStatus', { status: RequestStateStatus.Error, msg: err });
    });
  },
  fetchPackagePricing({ commit, rootState }) {
    commit('updateResourceStatus', { status: RequestStateStatus.Loading });

    return Package.getPackagePricing(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      commit('updatePackage', { data });
      commit('updatePackageStatus', { status: RequestStateStatus.Loaded });
    }).catch((err) => {
      // console.error(err);
      console.log('Error while fetching pricing ');
      commit('updatePackageStatus', { status: RequestStateStatus.Error, msg: err });
    });
  },
  fetchUpfronts({ commit, rootState }) {
    commit('updateUpfrontsStatus', { status: RequestStateStatus.Loading });
    return PayGoResources.getUpFronts(rootState.apiInstance, rootState.inMemoryCache,
      rootState.project.activeProjectCtrl.currency).then((data) => {
      commit('updateUpfronts', { data });
      commit('updateUpfrontsStatus', { status: RequestStateStatus.Loaded });
    }).catch((err) => {
      // console.error(err);
      console.log('Error while fetch upfronts ');
      commit('updateUpfrontsStatus', { status: RequestStateStatus.Error, msg: err });
    });
  },

  fetchResourcePricings({ commit, rootState }) {
    commit('updateResourceStatus', { status: RequestStateStatus.Loading });

    return PayGoResources.getResourcesInfo(rootState.apiInstance, rootState.inMemoryCache).then((data) => {
      console.log('resd', data);
      commit('updateResources', { data });
      commit('updateResourceStatus', { status: RequestStateStatus.Loaded });
    }).catch((err) => {
      // console.error(err);
      console.log('Error while fetching pricing ');
      commit('updateResourceStatus', { status: RequestStateStatus.Error, msg: err });
    });
  },

  fetchPrepayAmounts({ commit, rootState }) {
    return PayGoResources.getPrepayAmounts(rootState.apiInstance).then((data) => {
      commit('updatePrepaidAmounts', { data });
    }).catch((err) => {
      console.error(err);
    });
  },
};

const mutations = {
  updateLicenses(state, { data }) {
    Vue.set(state.payGoResources, 'licenses', data.licenses);
  },
  updateUpfronts(state, { data }) {
    state.payGoResources.upfrontOffers = data.upfronts;
  },
  updateResources(state, { data }) {
    state.payGoResources.resources = data.resources;
  },
  updatePackage(state, { data }) {
    state.package.packageInfo = data.data.data.response;
  },
  updatePrepaidAmounts(state, { data }) {
    state.payGoResources.prePaidAmounts = data.prepaid_amounts;
  },
  updateLicenseStatus(state, { status, msg }) {
    state.payGoResources.licenseStatus = status;
    state.payGoResources.ref = msg;
  },
  updateResourceStatus(state, { status }) {
    state.payGoResources.resourceStatus = status;
  },
  updatePackageStatus(state, { status }) {
    state.package.packageStatus = status;
  },
  updateUpfrontsStatus(state, { status, msg }) {
    state.payGoResources.upfrontStatus = status;
    state.payGoResources.ref = msg;
  },
};

export default {
  namespaced: true,
  state: initState,
  getters,
  actions,
  mutations,
};
